import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./routes/Home";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Pricing from "./routes/Pricing.js";
import Privacy from "./routes/Privacy";
import Terms from "./routes/Terms";
import Refund from "./routes/Refund";
import Thanks from "./routes/Thanks";

// Logic
import Dashboard from "./routes/Dashboard";
import Notes from "./routes/Notes";
import Settings from "./routes/Settings";
import Share from "./routes/Share";

// AuthNav
import ResetPassword from "./routes/ResetPassword";
import Login from "./routes/Login";
import Signup from "./routes/Signup";

function App() {
  useEffect(() => {
    const updateTitle = () => {
      const path = window.location.pathname;
      let title = "Default Title"; // Fallback title

      switch (path) {
        case "/":
          title = "VokalNotes | Capture Your Thoughts - Home";
          break;
        case "/about":
          title = "About Us | VokalNotes";
          break;
        case "/login":
          title = "Login | VokalNotes";
          break;
        case "/signup":
          title = "Sign Up | VokalNotes";
          break;
        case "/dashboard":
          title = "Dashboard | VokalNotes";
          break;
        case "/notes":
          title = "Notes | VokalNotes";
          break;
        case "/settings":
          title = "Settings | VokalNotes";
          break;
        case "/share/:id":
          title = "Share | VokalNotes";
          break;
        case "/contact":
          title = "Contact | VokalNotes";
          break;
        case "/pricing":
          title = "Pricing | VokalNotes";
          break;
        case "/privacy":
          title = "Privacy Policy | VokalNotes";
          break;
        case "/terms":
          title = "Terms of Service | VokalNotes";
          break;
        case "/refund":
          title = "Refund Policy | VokalNotes";
          break;
        case "/thank-you":
          title = "Thank You | VokalNotes";
          break;
        case "/reset-password":
          title = "Reset Password | VokalNotes";
          break;
        default:
          title = "VokalNotes"; // Fallback title
      }

      document.title = title;
      console.log(`Page title set to: ${title}`); // Debug log
    };

    updateTitle();
    window.addEventListener("popstate", updateTitle); // Update title on back/forward navigation

    return () => {
      window.removeEventListener("popstate", updateTitle);
    };
  }, []);

  return (
    <div className="min-h-screen font-sans">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/notes" element={<Notes />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/share/:id" element={<Share />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/thank-you" element={<Thanks />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
