import React from "react";

export default function About() {
	return (
		<div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
			<div className="container mx-auto p-8 max-w-screen-md my-8 bg-white rounded-lg">
					<h1 className="text-3xl text-center font-bold mb-8">About Us</h1>
					<p className="text-lg mb-4">
						Welcome to VokalNotes, where innovation meets simplicity in the world of voice
						notes. Our journey began with a simple idea: to empower individuals with a seamless
						way to capture, organize, and recall their thoughts and ideas. In today’s fast-paced
						environment, we understand that time is precious, and the need for effective
						communication and organization has never been greater.
					</p>
					<h3 className="text-2xl font-bold mb-4">Our Mission</h3>
					<p className="text-lg mb-4">
						At VokalNotes, our mission is to transform the way people interact with their
						voices. We believe that everyone has a story to tell, ideas to share, and memories to
						preserve. Our goal is to provide a user-friendly platform that allows you to record,
						transcribe, and summarize your voice notes effortlessly—no matter where you are or
						what language you speak.
					</p>
					<h3 className="text-2xl font-bold mb-4">Our Values</h3>
					<p className="text-lg mb-4">
						We are committed to delivering an exceptional user experience while upholding our
						core values:
						<ul className="list-disc pl-5 mt-4">
							<li>
								Innovation: We continuously strive to improve our app, integrating the latest
								technology to meet our users' needs.
							</li>
							<li>
								User-Centric Design: Our app is designed with you in mind. We prioritize ease of
								use and accessibility, ensuring that anyone can benefit from our features.
							</li>
							<li>
								Community: We believe in the power of community and collaboration. We value your
								feedback and are dedicated to making our app even better through your insights.
							</li>
							<li>
								Integrity: We uphold the highest standards of integrity, ensuring that your data is
								handled with care and respect.
							</li>
						</ul>
					</p>
					<h3 className="text-2xl font-bold mb-4">
						Join Us on This Journey
					</h3>
					<p className="text-lg">
						We are more than just an app; we are a community of thinkers, creators, and doers.
						Whether you are a student, a professional, or someone who simply loves to capture
						life’s moments, our app is designed to support you. We invite you to join us on this
						journey of voice and expression. Sign up today and start capturing
						your thoughts like never before. Together, let’s turn voices into stories. For any
						inquiries, feedback, or support, feel free to reach out to us at usfslk@icloud.com
					</p>
			</div>
		</div>
	);
}
