import React, { useState } from 'react';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const [loading, setLoading] = useState(false); // State for loading

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Sending email..."); // Debug log
    setLoading(true); // Set loading to true

    const data = {
      service_id: "service_ll46zcn",
      template_id: "template_73vaa17", // Update to your template ID
      user_id: "WLtM2dil32dQpG-S-", // Replace with your actual user ID
      template_params: {
        name: name,
        email: email,
        message: message,
      },
    };

    fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      console.log('Raw response:', response); // Debug log
      return response.text(); // Get the response as text
    })
    .then((text) => {
      console.log('Response text:', text); // Debug log
      console.log('Email sent successfully'); // Debug log
      setSuccessMessage('Email sent successfully!'); // Set success message
      setErrorMessage(''); // Clear any previous error message
    })
    .catch((error) => {
      console.log('Email sending error:', error); // Debug log
      setErrorMessage('Failed to send email. Please try again.'); // Set error message
      setSuccessMessage(''); // Clear any previous success message
    })
    .finally(() => {
      setLoading(false); // Set loading to false after the request completes
    });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="container mx-auto mx-2 sm:mx-0 p-8 max-w-screen-sm my-8 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
        <form onSubmit={sendEmail} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-lg font-medium text-gray-700">Name</label>
            <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
          </div>
          <div>
            <label htmlFor="email" className="block text-lg font-medium text-gray-700">Email</label>
            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
          </div>
          <div>
            <label htmlFor="message" className="block text-lg font-medium text-gray-700">Message</label>
            <textarea id="message" name="message" rows={4} value={message} onChange={(e) => setMessage(e.target.value)} className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"></textarea>
          </div>
          <div>
            <button type="submit" disabled={loading || successMessage !== ''} className={`w-full py-2 px-4 ${loading ? 'bg-gray-400' : 'bg-black'} text-white font-semibold rounded-md shadow-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}>
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
        {successMessage && <p className="text-green-500 mt-4 text-center">{successMessage}</p>} {/* Success message */}
        {errorMessage && <p className="text-red-500 mt-4 text-center">{errorMessage}</p>} {/* Error message */}
      </div>
    </div>
  );
}