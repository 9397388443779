import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";
import Placeholder from "../components/Placeholder";
// import Pricing from "../components/Pricing";
import axios from 'axios';
import { ClockIcon } from "@heroicons/react/24/outline";
import { ReactMediaRecorder } from "react-media-recorder"; // Add this import
import RecordRTC from 'recordrtc';

function useCredits(user, getAccessToken) {
  const [state, setState] = useState(() => ({
    credits: null,
    error: null,
    isLoading: false
  }));
  const fetchedRef = useRef(false);

  const fetchCredits = useCallback(async () => {
    if (!user || fetchedRef.current) return;

    fetchedRef.current = true;
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      console.log('Fetching credits for user:', user.uid);
      const token = await getAccessToken();
      const response = await axios.get('https://voicenotes-server.onrender.com/api/user/credits', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('Credits fetched:', response.data.credits);
      setState({ credits: response.data.credits, error: null, isLoading: false });
    } catch (err) {
      console.error('Error fetching credits:', err);
      setState({ credits: null, error: err, isLoading: false });
    }
  }, [user, getAccessToken]);

  useEffect(() => {
    fetchCredits();
  }, [fetchCredits]);

  const forceRefetch = useCallback(() => {
    console.log('Force refetching credits');
    fetchedRef.current = false;
    fetchCredits();
  }, [fetchCredits]);

  return { ...state, forceRefetch };
}

const Dashboard = React.memo(() => {
  const { user, loading, getAccessToken, checkTokenExpiration } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { credits, error: creditsError, isLoading: creditsLoading, forceRefetch } = useCredits(user, getAccessToken);
  const [userLanguage, setUserLanguage] = useState({ label: "English", value: "en-US" });
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const [state, setState] = useState(() => ({
    isRecording: false,
    audioURL: null,
    permission: false,
    audioChunks: [],
    uploadProgress: null,
    transcript: "",
    insufficientCredits: false,
    selectedFile: null,
    fileTranscript: "",
    isTranscribing: false
  }));

  const [recordingDuration, setRecordingDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [fileDuration, setFileDuration] = useState(null);
  const [hasSufficientCredits, setHasSufficientCredits] = useState(true);

  const mediaRecorder = useRef(null);
  const deepgramConnection = useRef(null);

  const [displayedTranscript, setDisplayedTranscript] = useState("");
  const transcriptIndex = useRef(0);

  useEffect(() => {
    if (state.transcript.length > transcriptIndex.current) {
      const interval = setInterval(() => {
        setDisplayedTranscript((prev) => prev + state.transcript[transcriptIndex.current]);
        transcriptIndex.current += 1;
        if (transcriptIndex.current >= state.transcript.length) {
          clearInterval(interval);
        }
      }, 25); // Adjust the speed of typing effect here (faster)
      return () => clearInterval(interval);
    }
  }, [state.transcript]);

  useEffect(() => {

    const initializeDashboard = async () => {
      if (!loading && user) {
        try {
          await checkTokenExpiration();
          const token = await getAccessToken();
          // Use the token for initial data fetching
          await fetchUserLanguage(token);
          await fetchCredits(token);
        } catch (error) {
          console.error("Error initializing dashboard:", error);
          // Handle error (e.g., redirect to login if token refresh failed)
        }
      }
    };

    initializeDashboard();
  }, [user, loading, getAccessToken, checkTokenExpiration]);

  const fetchUserLanguage = async (token) => {
    try {
      const response = await axios.get('https://voicenotes-server.onrender.com/api/user/settings', {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.status === 200) {
        const settings = response.data;
        setUserLanguage({
          label: settings.language?.label || "English",
          value: settings.language?.value || "en-US"
        });
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching user settings:", error);
    }
  };

  const fetchCredits = async (token) => {
    try {
      const response = await axios.get('https://voicenotes-server.onrender.com/api/user/credits', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (response.status === 200) {
        setState(prevState => ({ ...prevState, credits: response.data.credits }));
      }
    } catch (error) {
      console.error("Error fetching credits:", error);
    }
  };

  const updateUserCredits = useCallback(async (usedSeconds) => {
    console.log('updateUserCredits :',);
    console.log('usedSeconds :', usedSeconds);
    try {
      const usedCredits = usedSeconds / 60; // Convert seconds to minutes (as a float)
      const currentCredits = parseFloat(credits) || 0; // Ensure credits is a number
      const newTotalCredits = Math.max(0, parseFloat((currentCredits - usedCredits).toFixed(2))); // Ensure non-negative and round to 2 decimals
      console.log("Updating credits:", { currentCredits, usedCredits, newTotalCredits });

      const token = await getAccessToken();
      const response = await axios.post('https://voicenotes-server.onrender.com/api/user/update-credits',
        { credits: newTotalCredits },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );

      if (response.status === 200) {
        console.log("Credits updated successfully. New total:", response.data.credits);
        forceRefetch();
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error updating credits:", error);
    }
  }, [credits, getAccessToken, forceRefetch]);


  useEffect(() => {
    let isMounted = true;

    const initializeMicrophone = async () => {
      await getMicrophonePermission();
      if (isMounted) {
        // Update state if needed after getting microphone permission
      }
    };

    initializeMicrophone();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const resetState = () => {
      setState(prev => ({
        ...prev,
        isRecording: false,
        audioURL: null,
        transcript: "",
        audioChunks: [],
        uploadProgress: null
      }));
      if (deepgramConnection.current) {
        deepgramConnection.current.finish();
        deepgramConnection.current = null;
      }
    };

    resetState();
  }, [location]);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setState(prev => ({ ...prev, permission: true }));
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const saveTranscriptToFirebase = useCallback(async (userId, fileId, transcript) => {
    try {
      console.log("Saving transcript:", { userId, fileId, transcript });
      const token = await getAccessToken();
      const response = await axios.post('https://voicenotes-server.onrender.com/api/user/save-transcript',
        {
          userId,
          fileId,
          transcript
        },
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.status === 200) {
        console.log("Transcript saved successfully via API");
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error saving transcript via API:", error);
    }
  }, [getAccessToken]);


  const startRecording = useCallback(async () => {
    setState(prev => ({ ...prev, isRecording: true }));

    if (credits === null || credits <= 0) {
      console.log("Insufficient credits to start recording."); // Added log

      setState(prev => ({ ...prev, insufficientCredits: true }));
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      // Initialize RecordRTC with stream
      const recorder = new RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm;codecs=opus' || 'audio/mp4',
        timeSlice: 1000, // Time slice for audio chunks (1 second)
        sampleRate:  16000, // Only set sample rate for WAV files if needed
        ondataavailable: (blob) => {
          // Convert blob to array buffer and send to Deepgram
          if (deepgramConnection.current) {
            const reader = new FileReader();
            reader.onloadend = () => {
              if (reader.result && deepgramConnection.current) {
                deepgramConnection.current.send(reader.result); // Send audio chunk to Deepgram
              }
            };
            reader.readAsArrayBuffer(blob);
          }
        },
      });

      mediaRecorder.current = recorder;
      setState(prev => ({ ...prev, audioChunks: [] }));

      recorder.startRecording(); // Start RecordRTC

      // Initialize Deepgram connection
      const deepgram = createClient(process.env.REACT_APP_DEEPGRAM_API_KEY);
      const connection = deepgram.listen.live({
        model: "nova-2",
        language: userLanguage.value,
        smart_format: true,
      });

      connection.on(LiveTranscriptionEvents.Open, () => {
        console.log("Deepgram connection opened"); // Added log
      });

      connection.on(LiveTranscriptionEvents.Transcript, (data) => {
        console.log("Transcript data received"); // Changed to stringify the data
        setState(prev => ({ ...prev, transcript: prev.transcript + " " + data.channel.alternatives[0].transcript }));
      });

      connection.on(LiveTranscriptionEvents.Error, (err) => {
        console.log("Deepgram error:", String(err)); // Added log
      });

      deepgramConnection.current = connection;

    } catch (error) {
      console.log("Error starting recording:", error); // Added log
      setState(prev => ({ ...prev, isRecording: false }));
    }
  }, [credits, userLanguage]);

  const uploadFile = useCallback(async (audioBlob, fileId) => {
    const storage = getStorage();
    const filePath = `users/${user.uid}/notes/${fileId}.webm`;
    const storageRef = ref(storage, filePath);

    try {
      const uploadTask = uploadBytesResumable(storageRef, audioBlob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setState(prev => ({ ...prev, uploadProgress: progress }));
        },
        (error) => {
          console.error("Error uploading file:", error);
          setState(prev => ({ ...prev, uploadProgress: null }));
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("File uploaded successfully. Download URL:", downloadURL);

          // Save note metadata to Firestore
          const db = getFirestore();
          await addDoc(collection(db, "notes"), {
            userId: user.uid,
            title: `Note ${fileId}`,
            filePath: filePath,
            createdAt: new Date(),
          });

          console.log("File uploaded and note saved successfully.");
          setState(prev => ({ ...prev, uploadProgress: null }));

          // Save transcript to Firebase Realtime Database
          await saveTranscriptToFirebase(user.uid, fileId, state.transcript);

          // Set upload success message
          setUploadSuccess(true);
          // Hide success message after 3 seconds
          setTimeout(() => setUploadSuccess(false), 3000);
        }
      );
    } catch (error) {
      console.error("Error uploading file or saving note:", error);
      setState(prev => ({ ...prev, uploadProgress: null }));
    }
  }, [user, saveTranscriptToFirebase, state.transcript]);


  const stopRecording = useCallback(async () => {
    if (!mediaRecorder.current) {
      console.error("No active recording to stop");
      setState(prev => ({ ...prev, isRecording: false }));
      return;
    }

    console.log("Stopping recording");


    mediaRecorder.current.stopRecording(() => {
      const audioBlob = mediaRecorder.current.getBlob();
      handleAudioStop(audioBlob);
    });

    mediaRecorder.current.onstop = async () => {
      console.log("Recording stopped, processing audio");
      const audioBlob = new Blob(state.audioChunks, { type: "audio/webm" });
      handleAudioStop(audioBlob);
    };

    // Common function to handle audio stop logic
    const handleAudioStop = async (audioBlob) => {
      const audioUrl = URL.createObjectURL(audioBlob);
      setState(prev => ({ ...prev, audioURL: audioUrl, audioChunks: [], isRecording: false }));

      // Calculate duration and update credits
      const audioDurationSeconds = Math.ceil(audioBlob.size / 16000); // Approximate duration in seconds
      console.log("Calculated audio duration (seconds):", audioDurationSeconds);
      console.log("Updating credits for user:", user.uid);
      await updateUserCredits(audioDurationSeconds);


      // Upload to Firebase Storage
      const fileId = Date.now().toString();
      console.log("Uploading file with ID:", fileId);
      await uploadFile(audioBlob, fileId);

      // Close Deepgram connection
      if (deepgramConnection.current) {
        console.log("Closing Deepgram connection");
        deepgramConnection.current.finish();
        deepgramConnection.current = null;
      }
    };
  }, [state.audioChunks, user, updateUserCredits, uploadFile]);

  const handleFileSelect = useCallback((event) => {
    const file = event.target.files?.[0];
    if (file) {
      setState(prev => ({ ...prev, selectedFile: file }));

      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const reader = new FileReader();

      reader.onload = function (e) {
        audioContext.decodeAudioData(e.target.result, function (buffer) {
          const durationInSeconds = buffer.duration;
          const durationInMinutes = durationInSeconds / 60;
          const minutes = Math.floor(durationInMinutes);
          const seconds = Math.round((durationInMinutes - minutes) * 60);
          setFileDuration(`${minutes}:${seconds.toString().padStart(2, '0')}`);

          // Check if user has enough credits
          setHasSufficientCredits(credits >= durationInMinutes);
        }, function (e) {
          console.log('Error decoding audio data', e);
          const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
          setFileDuration(`${fileSizeInMB} MB`);
          setHasSufficientCredits(false);
        });
      };

      reader.onerror = function (e) {
        console.log('Error reading file', e);
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setFileDuration(`${fileSizeInMB} MB`);
        setHasSufficientCredits(false);
      };

      reader.readAsArrayBuffer(file);
    }
  }, [credits]);

  const transcribeFile = async () => {
    console.log("transcribeFile function called");
    if (!state.selectedFile || !user) {
      console.log("No file selected or user not logged in", { file: !!state.selectedFile, user: !!user });
      return;
    }

    console.log("Starting transcription process");
    setState(prev => ({ ...prev, isTranscribing: true, fileTranscript: "" }));

    try {
      console.log("Preparing form data");
      const formData = new FormData();
      formData.append("file", state.selectedFile);
      const fileId = Date.now().toString();
      console.log("File ID generated:", fileId);

      console.log("Sending request to transcribe API");
      const token = await getAccessToken();
      const response = await fetch("https://voicenotes-server.onrender.com/api/user/transcribe", {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      console.log("Response received from API", { status: response.status, statusText: response.statusText });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("API Error Response:", errorText);
        throw new Error(`API error: ${response.status} ${response.statusText}. ${errorText}`);
      }

      const data = await response.json();
      console.log("Parsed response data:", data);

      if (!data.transcript) {
        throw new Error("No transcript returned from the API");
      }

      console.log("Transcript received successfully");
      setState(prev => ({ ...prev, fileTranscript: data.transcript }));

      console.log("Saving transcript to Firebase");
      await saveTranscriptToFirebase(user.uid, fileId, data.transcript);

      console.log("Uploading file to Firebase Storage");
      const storage = getStorage();
      const filePath = `users/${user.uid}/notes/${fileId}`;
      const storageRef = ref(storage, filePath);

      const uploadTask = uploadBytesResumable(storageRef, state.selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log("Upload progress:", progress);
          setState(prev => ({ ...prev, uploadProgress: progress }));
        },
        (error) => {
          console.error("Error uploading file:", error);
          setState(prev => ({ ...prev, uploadProgress: null }));
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("File uploaded successfully. Download URL:", downloadURL);

          console.log("Saving note metadata to Firestore");
          const db = getFirestore();
          await addDoc(collection(db, "notes"), {
            userId: user.uid,
            title: `Note ${fileId}`,
            filePath: filePath,
            createdAt: new Date(),
          });

          setState(prev => ({ ...prev, uploadProgress: null }));
          console.log("File uploaded and transcript saved successfully.");
        }
      );

      console.log("Updating user credits");
      const audioDurationSeconds = Math.ceil(state.selectedFile.size / 16000);
      console.log("Calculated audio duration (seconds):", audioDurationSeconds);
      await updateUserCredits(audioDurationSeconds);

      // Set upload success message
      setUploadSuccess(true);
      // Hide success message after 3 seconds
      setTimeout(() => setUploadSuccess(false), 3000);

    } catch (error) {
      console.error("Error in transcribeFile function:", error);
      setState(prev => ({ ...prev, fileTranscript: `Error: ${error.message}` }));
    } finally {
      console.log("Transcription process completed");
      setState(prev => ({ ...prev, isTranscribing: false }));
    }
  };

  useEffect(() => {
    let interval;
    if (state.isRecording) {
      interval = setInterval(() => {
        setRecordingDuration(prev => prev + 1);
      }, 1000);
    } else {
      setRecordingDuration(0);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [state.isRecording]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const formatTime = (date) => {
    const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };
    const timeOptions = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };
    const dateString = date.toLocaleDateString('en-US', dateOptions).replace(/,/g, '.');
    const timeString = date.toLocaleTimeString('en-US', timeOptions);
    return (
      <>
        {timeString.toLowerCase()}<br />
        {dateString.toLowerCase()}
      </>
    );
  };

  const memoizedContent = useMemo(() => (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-t from-gray-200 to-white p-8">

      <h1 className="text-3xl font-bold mb-8 text-blue-900">Dashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full max-w-4xl">
        <div className="rounded-lg p-4 border-4 border-gray flex items-center justify-center bg-white">
          <p className="text-lg font-semibold text-center">{formatTime(currentTime)}</p>
        </div>
        <div className="rounded-lg p-4 border-4 border-gray flex items-center justify-center bg-white">
          {credits !== null && (
            <p className="text-lg font-semibold text-center">Credits:
              <br />{parseFloat(credits).toFixed(2)}</p>
          )}
        </div>
        <div className="rounded-lg p-4 border-4 border-gray flex items-center justify-center bg-white">
          {userLanguage && (
            <p className="text-lg font-semibold text-center">Language:<br />
              <span className="font-semibold">{userLanguage.label}</span></p>
          )}
        </div>
      </div>

      {creditsError && <p className="text-white p-4 bg-red-800 mt-8">Error fetching credits: {creditsError.message}</p>}

      {credits !== null && credits <= 0 && (
        <p className="text-red-600 font-semibold mt-8">
          Insufficient balance. Please <a className="text-black underline" href="/pricing">upgrade</a> to continue recording.
        </p>
      )}

      {state.insufficientCredits && (
        <p className="text-red-600 font-semibold mt-8">
          Insufficient balance. Please <a className="text-black underline" href="/pricing">upgrade</a> to continue recording.
        </p>
      )}



      <div className="flex gap-4">
        {state.permission && !state.isRecording ? (
          <button
            onClick={startRecording}
            className={`bg-gradient-to-l from-red-900 to-red-600 text-white font-bold py-4 
              px-8 rounded-full text-xl shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mt-8 mb-8 ${credits === null || credits <= 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            disabled={credits === null || credits <= 0}
          >
            Start Recording
          </button>
        ) : null}
        {state.isRecording && (
          <button
            onClick={stopRecording}
            className="bg-gradient-to-l from-red-900 to-red-600 hover:bg-red-700 text-white font-bold py-4 px-8 rounded-full text-xl shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mt-8 mb-8 animate-pulse"
          >
            Stop Recording
          </button>
        )}
        {!state.selectedFile ? (
          <label
            htmlFor="fileInput"
            className="bg-gradient-to-l from-blue-700 to-black text-white 
            font-bold py-4 px-8 rounded-full text-xl text-center shadow-lg transition duration-300 ease-in-out 
            transform hover:scale-105 mt-8 mb-8 cursor-pointer"
          >
            Upload File
          </label>
        ) : (
          <button
            onClick={transcribeFile}
            className={`bg-gradient-to-l from-blue-700 to-black text-white font-bold py-4 
              px-8 rounded-full text-xl shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mt-8 mb-8 ${(!hasSufficientCredits || state.isTranscribing) ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={!hasSufficientCredits || state.isTranscribing}
          >
            {state.isTranscribing ? "Transcribing..." : "Transcribe File"}
          </button>
        )}
        <input
          type="file"
          accept="audio/*"
          onChange={handleFileSelect}
          id="fileInput"
          className="hidden"
        />

        <div className="flex flex-col items-center"></div>
      </div>

      {state.audioURL && (
        <div className="mt-4 mb-4">
          <audio src={state.audioURL} controls></audio>
        </div>
      )}

      {state.selectedFile && (
        <p className="text-sm text-gray-600 mb-2">
          {state.selectedFile.name}
          {fileDuration && ` (${fileDuration})`}
        </p>
      )}

      {state.selectedFile && !hasSufficientCredits && (
        <p className="text-blue-600 font-semibold mt-2 text-center">
          You need at least {Math.ceil(parseFloat(fileDuration))} credits to transcribe this file.
          <br />
          Please <a className="text-black underline" href="/pricing">Upgrade</a> to continue using our app.
        </p>
      )}

      {state.uploadProgress !== null && (
        <div className="w-64">
          <div className="bg-gray-200 rounded-full h-4 mb-4 mt-4 w-full">
            <div
              className="bg-black h-4 rounded-full"
              style={{ width: `${state.uploadProgress}%`, maxWidth: "100%" }}
            ></div>
          </div>
          <p className="text-center">Uploading {state.uploadProgress}% ...</p>
        </div>
      )}

      {state.isRecording && (
        <div className="flex items-center justify-center space-x-2 mt-4 mb-2 bg-gray-100 p-2 rounded-lg">
          <ClockIcon className="w-6 h-6 text-black" />
          <span className="font-semibold">{formatDuration(recordingDuration)}</span>
        </div>
      )}

      {state.transcript && (
        <div className="mt-4 p-4 mb-4 bg-white rounded max-w-lg w-full">
          <p>{displayedTranscript}</p>
        </div>
      )}

      {state.fileTranscript && (
        <div className="mt-4 p-4 mb-4 bg-white rounded max-w-lg w-full">
          <p>{state.fileTranscript}</p>
        </div>
      )}


      {uploadSuccess && (
        <div className="bg-black text-white mt-4 p-4 text-center rounded-lg">
          Note uploaded and saved successfully!
        </div>
      )}

      {/* <Pricing /> */}
    </div>
  ), [credits, state, user, userLanguage, uploadSuccess, recordingDuration, currentTime, fileDuration, hasSufficientCredits, displayedTranscript]);

  if (loading || creditsLoading) {
    console.log("Dashboard: Still loading...");
    return <div className="flex justify-center items-center h-screen bg-gray-100">
      <Placeholder />
    </div>;
  }

  console.log("Dashboard: Rendering memoized content");
  return memoizedContent;
}, (prevProps, nextProps) => {
  // Custom comparison function for React.memo
  // Return true if you want to skip re-render
  return prevProps.user === nextProps.user && prevProps.loading === nextProps.loading;
});

export default Dashboard;
