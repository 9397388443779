import React, { useEffect, useState, useRef, useCallback } from "react";
import { getStorage, ref, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { auth } from "../firebase/config";
import {
  GoogleGenerativeAI,
  HarmBlockThreshold,
  HarmCategory,
} from "@google/generative-ai";
import { useNavigate } from "react-router-dom";
import { ShareIcon } from '@heroicons/react/24/outline';
import { TrashIcon, PlayIcon, SpeakerWaveIcon } from '@heroicons/react/24/solid';
import Placeholder from "../components/Placeholder";
import { useAuth } from "../context/AuthContext";
import axios from 'axios';

const safetySettings = [
  {
    category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
    threshold: HarmBlockThreshold.BLOCK_NONE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_HARASSMENT,
    threshold: HarmBlockThreshold.BLOCK_NONE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
    threshold: HarmBlockThreshold.BLOCK_NONE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
    threshold: HarmBlockThreshold.BLOCK_NONE,
  },
];

const API_KEY = process.env.REACT_APP_GOOGLE_AI_API_KEY;

const genAI = new GoogleGenerativeAI(API_KEY);
const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash",
  safetySettings: safetySettings,
});

function useUserData(user, accessToken) {
  const [state, setState] = useState({
    allUserData: null,
    error: null,
    isLoading: false,
    userLanguage: null
  });
  const fetchedRef = useRef(false);

  const fetchAllUserData = useCallback(async () => {
    if (!user || !accessToken || fetchedRef.current) return;

    fetchedRef.current = true;
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      console.log("Fetching user data with token:", accessToken ? "Token present" : "No token");
      const transcriptsResponse = await axios.get('https://voicenotes-server.onrender.com/api/user/fetch-transcript', {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      });
      const summariesResponse = await axios.get('https://voicenotes-server.onrender.com/api/user/fetch-summary', {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      });
      const settingsResponse = await axios.get('https://voicenotes-server.onrender.com/api/user/settings', {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      });
      setState({
        allUserData: {
          transcripts: transcriptsResponse.data.transcripts,
          summaries: summariesResponse.data.summaries
        },
        error: null,
        isLoading: false,
        userLanguage: settingsResponse.data.language?.value || 'en'
      });
    } catch (err) {
      console.error("Error fetching user data:", err);
      setState({ allUserData: null, error: err, isLoading: false, userLanguage: 'en' });
    }
  }, [user, accessToken]);

  useEffect(() => {
    fetchAllUserData();
  }, [fetchAllUserData]);

  return state;
}

export default function Notes() {
  const { user, accessToken } = useAuth();
  const navigate = useNavigate();
  const { allUserData, error: userDataError, isLoading: userDataLoading, userLanguage } = useUserData(user, accessToken);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentAudioUrl, setCurrentAudioUrl] = useState(null);
  const [summarizing, setSummarizing] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedNoteId, setExpandedNoteId] = useState(null);
  const [copyMessage, setCopyMessage] = useState(null);
  const [playbackRate, setPlaybackRate] = useState(1);
  const audioRef = useRef(null);

  useEffect(() => {
    if (!loading && (!user || !accessToken)) {
      navigate("/login");
    }
  }, [user, loading, navigate, accessToken]);

  useEffect(() => {
    let isMounted = true;

    const fetchNotes = async () => {
      if (user && accessToken && allUserData && isMounted) {
        setLoading(true);
        try {
          const storage = getStorage();
          const listRef = ref(storage, `users/${user.uid}/notes`);
          const { items } = await listAll(listRef);

          if (items.length === 0) {
            console.log("No notes found in the notes collection.");
            setNotes([]);
          } else {
            const fetchedNotes = await Promise.all(
              items.reverse().map(async (itemRef) => {
                try {
                  const audioUrl = await getDownloadURL(itemRef);
                  const fileIdWithExtension = itemRef.name;
                  const fileId = fileIdWithExtension.split('.')[0];

                  const transcript = allUserData.transcripts?.[fileId]?.transcript || "No transcript available";
                  const summaryData = allUserData.summaries?.[fileId];

                  const title = summaryData?.title || fileIdWithExtension;
                  const summary = summaryData?.summary;
                  const details = summaryData?.details;

                  return {
                    id: fileIdWithExtension,
                    title: title,
                    audioUrl: audioUrl,
                    transcript: transcript,
                    summary: summary,
                    details: details,
                  };
                } catch (error) {
                  console.error("Error getting audio URL or parsing data for file:", itemRef.name, error);
                  return null;
                }
              })
            );
            if (isMounted) {
              setNotes(fetchedNotes.filter((note) => note !== null));
            }
          }
        } catch (error) {
          console.error("Error fetching notes:", error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    };

    fetchNotes();

    return () => {
      isMounted = false;
    };
  }, [user, accessToken, allUserData]);

  const playNote = (audioUrl) => {
    setCurrentAudioUrl(audioUrl);
  };

  const deleteNote = async (fileId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this note? This action cannot be undone."
      )
    ) {
      try {
        const user = auth.currentUser;
        const storage = getStorage();
        const storageRef = ref(storage, `users/${user.uid}/notes/${fileId}`);
        await deleteObject(storageRef);
        setNotes(notes.filter((note) => note.id !== fileId));
        console.log("Note deleted successfully");
      } catch (error) {
        console.error("Error deleting note:", error);
      }
    }
  };

  const saveSummaryToDatabase = async (noteId, title, summary, details) => {
    try {
      const fileIdWithoutExtension = noteId.replace(/\.[^/.]+$/, "");

      const response = await axios.post(
        'https://voicenotes-server.onrender.com/api/user/save-summary',
        {
          fileId: fileIdWithoutExtension,
          summary: {
            title,
            summary,
            details
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        console.log("Summary saved to database successfully");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error saving summary to database:", error);
      throw error; // Re-throw the error to be handled in the calling function
    }
  };

  async function summarize(noteId) {
    const note = notes.find((n) => n.id === noteId);
    if (!note) return;

    setSummarizing(noteId);

    const prompt = `Generate a concise summary for the voice note in JSON format with the following structure:
                  {
                  "status": "A brief status or title for the note",
                  "message": "A one-sentence summary of the note's content",
                  "details": [
                    "Bullet point 1 with additional details",
                    "Bullet point 2 with additional details",
                    "Bullet point 3 with additional details",
                    "Bullet point 4 with additional details",
                    "Bullet point 5 with additional details"
                  ]
                  }
                  The transcript is: "${note.transcript}"
                  Please provide the response in the ${userLanguage} language.`;

    try {
      const result = await model.generateContent(prompt);
      const responseText = result.response.text();
      console.log("API Response:", responseText);

      const jsonMatch = responseText.match(/\{[\s\S]*\}/);
      if (jsonMatch) {
        const jsonString = jsonMatch[0];
        const content = JSON.parse(jsonString);
        console.log("Parsed content:", content);

        let title = content.status || note.title;
        let summary = content.message || "";
        let details = content.details || [];

        if (content.action && typeof content.action === "string") {
          details.unshift(content.action);
        } else if (Array.isArray(content.action)) {
          details = content.action;
        }

        setNotes((prevNotes) =>
          prevNotes.map((n) =>
            n.id === noteId ? { ...n, title, summary, details, error: undefined } : n
          )
        );

        // Call the separate function to save summary to database
        await saveSummaryToDatabase(noteId, title, summary, details);
      } else {
        console.error("No valid JSON found in the response");
      }
    } catch (error) {
      console.error("Error summarizing note:", error);

      let errorMessage = "An unexpected error occurred";

      if (error instanceof Error) {
        const match = error.message.match(/\[(\d+)\s*\]\s*(.*?)(?:\s*\[{)/);
        if (match) {
          const [, errorCode, errorText] = match;
          errorMessage = `${errorCode}: ${errorText.trim()}`;
        } else {
          errorMessage = error.message.split('[{')[0].trim();
        }
      }

      setNotes((prevNotes) =>
        prevNotes.map((n) =>
          n.id === noteId ? { ...n, error: errorMessage } : n
        )
      );
    } finally {
      setSummarizing(null);
    }
  }

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredNotes = notes.filter((note) => {
    const query = searchQuery.toLowerCase();
    return (
      note.title.toLowerCase().includes(query) ||
      String(note.transcript).toLowerCase().includes(query) ||
      (note.summary && note.summary.toLowerCase().includes(query))
    );
  });

  const toggleExpand = (noteId) => {
    setExpandedNoteId(expandedNoteId === noteId ? null : noteId);
  };

  const copyToClipboard = (text, noteId) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyMessage({ id: noteId, message: "Copied!" });
        setTimeout(() => setCopyMessage(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handlePlaybackRateChange = (rate) => {
    setPlaybackRate(rate);
    if (audioRef.current) {
      audioRef.current.playbackRate = rate;
    }
  };

  const shareNote = async (noteId) => {
    console.log('Sharing note with id:', noteId);
    const note = notes.find((n) => n.id === noteId);
    if (!note || !user) {
      console.log('Note not found or user not logged in');
      return;
    }

    const cleanNoteId = noteId.split('.')[0];

    try {
      const response = await axios.post(
        'https://voicenotes-server.onrender.com/api/user/save-public-transcript',
        {
          userId: user.uid,
          noteId: cleanNoteId,
          title: note.title,
          audioUrl: note.audioUrl,
          transcript: note.transcript,
          summary: note.summary,
          details: note.details,
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        console.log('Note shared successfully');
        const shareId = response.data.shareId;

        setNotes((prevNotes) =>
          prevNotes.map((n) =>
            n.id === noteId ? { ...n, shareId } : n
          )
        );

        const shareUrl = `/share/${shareId}`;
        console.log('Redirecting to:', shareUrl);
        window.open(shareUrl, '_blank');
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error sharing note:", error);
      console.log('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
    }
  };

  if (loading || userDataLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <Placeholder />
      </div>
    );
  }

  if (userDataError) {
    return <div>Error loading user data: {userDataError.message}</div>;
  }

  return (
    <div className="bg-gradient-to-t from-gray-200 to-gray-100 flex justify-center min-h-screen">
      <div className="w-full max-w-4xl p-8 flex-grow">
        <h1 className="text-3xl font-bold mb-8 text-blue-900 text-center">Notes</h1>
        <input
          type="text"
          placeholder="Search notes..."
          value={searchQuery}
          onChange={handleSearch}
          className="mb-4 p-2 rounded w-full"
        />
        {filteredNotes.length === 0 ? (
          <p>No notes found.</p>
        ) : (
          <div className="space-y-4">
            {filteredNotes.map((note) => (
              <div
                key={note.id}
                className="bg-white p-8 rounded-lg flex flex-col justify-between"
              >
                <div>
                  <h3 className="font-semibold text-lg">{note.title}</h3>
                  <p className="text-gray-700 mt-2">
                    {expandedNoteId === note.id
                      ? String(note.transcript)
                      : `${String(note.transcript).substring(0, 200)}...`}
                  </p>
                  <div className="flex space-x-2 mt-2 justify-between">
                    <button
                      onClick={() => toggleExpand(note.id)}
                      className="text-blue-500 underline"
                    >
                      {expandedNoteId === note.id ? "Show Less" : "Show More"}
                    </button>
                    <button
                      onClick={() => copyToClipboard(String(note.transcript), note.id)}
                      className="text-blue-500 underline"
                    >
                      {copyMessage && copyMessage.id === note.id ? (
                        <p className="text-green-600 font-bold">{copyMessage.message}</p>
                      ) : (
                        <p>Copy to Clipboard</p>
                      )}
                    </button>
                  </div>
                  <hr className="mt-4" />
                  {note.summary && (
                    <div className="mt-2">
                      <h4 className="font-semibold mb-2">Summary:</h4>
                      <p className="text-gray-700">{note.summary}</p>
                    </div>
                  )}
                  {note.details && note.details.length > 0 && (
                    <div className="mt-2">
                      <h4 className="font-semibold mb-2">Details:</h4>
                      <ul className="list-disc list-inside text-gray-700">
                        {note.details.map((detail, index) => (
                          <li key={index}>{detail}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {note.error &&
                    <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                      <span class="font-medium">Error </span>{note.error}
                    </div>
                  }
                </div>
                <div className="mt-4">
                  <div className="flex justify-between">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => playNote(note.audioUrl)}
                        className="bg-gradient-to-r from-black to-gray-600 text-white px-2 py-1 rounded-full h-8 w-8 flex items-center justify-center"
                        disabled={currentAudioUrl === note.audioUrl}
                      >
                        {currentAudioUrl === note.audioUrl ? <SpeakerWaveIcon className="h-4 w-4 text-white mx-auto" /> : <PlayIcon className="h-4 w-4 text-white mx-auto" />}
                      </button>

                      <button
                        onClick={() => summarize(note.id)}
                        className="bg-gradient-to-r from-blue-900 to-blue-500 to-black text-white px-3 py-1 rounded-full h-8 text-xs"
                        disabled={note.summary}
                      >
                        {summarizing === note.id ? "..." : "Summarize"}
                      </button>

                      <button
                        onClick={() => shareNote(note.id)}
                        className={`bg-gradient-to-r from-green-900 to-green-500 text-white px-3 py-1 rounded-full h-8 flex items-center justify-center text-xs ${!note.summary ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!note.summary}
                      >
                        <ShareIcon className="h-3 w-3 text-white mr-1" />
                        Share
                      </button>
                    </div>
                    <button
                      onClick={() => deleteNote(note.id)}
                      className="bg-gradient-to-r from-red-900 to-red-500 text-white px-2 py-1 rounded-full h-8 w-8 flex items-center justify-center"
                    >
                      <TrashIcon className="h-4 w-4 text-white mx-auto" />
                    </button>
                  </div>
                </div>
                {currentAudioUrl === note.audioUrl && (
                  <div className="mt-4">
                    <audio
                      ref={audioRef}
                      src={currentAudioUrl}
                      controls
                      autoPlay
                      className="w-full"
                    />
                    <div className="mt-2 flex justify-center items-center">
                      <div className="flex items-center">
                        <label className="mr-2">Playback Speed:</label>
                        <select
                          value={playbackRate}
                          onChange={(e) => handlePlaybackRateChange(parseFloat(e.target.value))}
                          className="border rounded p-1"
                        >
                          <option value="0.5">0.5x</option>
                          <option value="1">1x</option>
                          <option value="1.5">1.5x</option>
                          <option value="2">2x</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
