import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { auth } from "../firebase/config";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();

  const refreshToken = async (retryCount = 0) => {
    console.log('Refresh token function called');
    try {
      console.log('Getting new ID token from Firebase');
      const idToken = await auth.currentUser.getIdToken(true);
      console.log('New ID token obtained:', idToken);

      console.log('Sending refresh request to server');
      const response = await axios.post('https://voicenotes-server.onrender.com/api/auth/refresh', { idToken });
      console.log('Server response:', response.data);

      const { accessToken } = response.data;
      console.log('New access token received:', accessToken);

      setAccessToken(accessToken);
      sessionStorage.setItem('accessToken', accessToken);
      console.log('Access token refreshed and stored in session storage');

      // Log the expiration time of the new token
      const tokenData = JSON.parse(atob(accessToken.split('.')[1]));
      const expirationTime = new Date(tokenData.exp * 1000);
      console.log('New token expires at:', expirationTime.toLocaleString());

    } catch (error) {
      console.error('Error refreshing token:', error);
      if (retryCount < 3) {
        console.log(`Retrying refresh... Attempt ${retryCount + 1}`);
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retry
        return refreshToken(retryCount + 1);
      }
      console.log('Max retries reached. Logging out.');
      await logout();
    }
  };

  const checkTokenExpiration = useCallback(async () => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = tokenData.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      if (currentTime >= expirationTime) {
        console.log('Token expired, refreshing...');
        await refreshToken();
        // Reload the current page after token refresh
        window.location.reload();
      } else {
        const timeUntilExpiration = expirationTime - currentTime;
        console.log(`Token expires in ${timeUntilExpiration / 1000} seconds`);
        if (timeUntilExpiration < 300000) { // Less than 5 minutes
          console.log('Token expiring soon, refreshing...');
          await refreshToken();
        } else {
          setTimeout(() => {
            console.log('Scheduled token refresh');
            refreshToken();
          }, timeUntilExpiration - 300000); // Refresh 5 minutes before expiration
        }
      }
    }
  }, [refreshToken]);

  const getAccessToken = useCallback(async () => {
    await checkTokenExpiration();
    return sessionStorage.getItem('accessToken');
  }, [checkTokenExpiration]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const storedToken = sessionStorage.getItem('accessToken');
        console.log('storedToken in onAuthStateChanged:', storedToken);
        if (storedToken) {
          setAccessToken(storedToken);
          console.log('Access token retrieved from session storage:', storedToken);
          checkTokenExpiration();
        } else {
          console.log('No access token found in session storage');
          await refreshToken();
        }
      } else {
        setAccessToken(null);
        sessionStorage.removeItem('accessToken');
      }
      setLoading(false);
    });

    // Add this block for periodic token checks
    const tokenCheckInterval = setInterval(() => {
      if (auth.currentUser) {
        console.log('Periodic token check');
        checkTokenExpiration();
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => {
      unsubscribe();
      clearInterval(tokenCheckInterval); // Clean up the interval
    };
  }, [checkTokenExpiration]);

  const login = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    setUser(userCredential.user);
    const idToken = await userCredential.user.getIdToken();
    try {
      const response = await axios.post('https://voicenotes-server.onrender.com/api/auth/login', { idToken });
      const { accessToken } = response.data;
      setAccessToken(accessToken);
      console.log('Access token set:', accessToken); // Add this line
      sessionStorage.setItem('accessToken', accessToken);
      navigate("/dashboard");
    } catch (error) {
      console.error('Error getting access token:', error);
      throw error;
    }
  };

  const logout = async () => {
    await signOut(auth);
    setAccessToken(null);
    setUser(null);
    sessionStorage.removeItem('accessToken');
    navigate("/login");
  };

  const value = {
    user,
    login,
    logout,
    loading,
    accessToken,
    setAccessToken,
    setUser,
    refreshToken,
    getAccessToken,
    checkTokenExpiration
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}