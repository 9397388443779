import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../context/AuthContext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/config";

export default function Signup() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { setAccessToken, setUser, accessToken } = useAuth();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		console.log("Signup form submitted with email:", email);
		try {
			// Send signup request to backend
			const signupResponse = await axios.post('https://voicenotes-server.onrender.com/api/auth/signup', {
				email,
				password
			});
			console.log("Signup response:", signupResponse.data);

			// On successful signup, log in the user
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			const idToken = await userCredential.user.getIdToken();
			console.log("ID token obtained:", idToken);

			// Send ID token to backend to get access token
			const loginResponse = await axios.post('https://voicenotes-server.onrender.com/api/auth/login', {
				idToken
			});
			console.log("Login response:", loginResponse.data);
			const { accessToken, user } = loginResponse.data;

			// Store the accessToken in sessionStorage
			sessionStorage.setItem('accessToken', accessToken);
			setAccessToken(accessToken);
			setUser(userCredential.user);
			console.log("Access token and user set in AuthContext");
			
			// Navigate to dashboard
			navigate("/dashboard");
		} catch (error) {
			console.error("Signup error:", error.response?.data?.message || error.message);
			setError(error.response?.data?.message || error.message);
			console.error("Error:", error);
		} finally {
			setLoading(false);
			console.log("Signup process completed");
		}
	};

	useEffect(() => {
		if (accessToken) {
			navigate("/dashboard");
		}
	}, [accessToken, navigate]);

	return (
		<div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
			<div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
				<div>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
						Sign up for an account
					</h2>
				</div>

				{error && <div className="text-red-500 text-sm text-center">{error}</div>}

				<form className="mt-8 space-y-6" onSubmit={handleSubmit}>
					<input type="hidden" name="remember" value="true" />
					<div className="rounded-md shadow-sm -space-y-px">
						<div>
							<label htmlFor="email-address" className="sr-only">
								Email address
							</label>
							<input
								id="email-address"
								name="email"
								type="email"
								autoComplete="email"
								required
								className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
								placeholder="Email address"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div>
							<label htmlFor="password" className="sr-only">
								Password
							</label>
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="new-password"
								required
								className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					</div>

					<div>
						<button
							type="submit"
							className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
								loading ? "bg-black" : "bg-blue-600 hover:bg-blue-700"
							} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out`}
							disabled={loading}
						>
							{loading ? "Signing up..." : "Sign up"}
						</button>
					</div>
				</form>
				<div className="text-center">
					<Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
						Already have an account? Sign in
					</Link>
				</div>
			</div>
		</div>
	);
}
