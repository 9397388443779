import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import AuthStateDisplay from "./AuthStateDisplay";
import {
    Bars3Icon,
} from "@heroicons/react/24/outline";
import logo from "../assets/logo.png";

export default function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const { user, logout, loading, accessToken } = useAuth(); // Added user, loading, accessToken from AuthContext

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.error("Failed to log out:", error);
        }
    };

    return (
        <>
            <nav className="bg-black text-white p-4 ">
                <div
                    className="w-full flex justify-between items-center"
                    ref={menuRef}
                >
                    <div className="flex items-center">
                        <img
                            src={logo}
                            alt="logo"
                            className="w-10 h-10 mr-2"
                        />
                        <a href="/" className="text-xl font-bold">VokalNotes</a>
                    </div>
                    <button onClick={toggleMenu} className="md:hidden text-white">
                        <Bars3Icon className="w-10 h-10 sm:w-12 text-white" />
                    </button>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <ul
                            className={` z-50 flex flex-col md:flex-row items-center md:items-center absolute md:relative right-0 ${isMenuOpen ? "top-14" : "-top-full"
                                } md:top-0 bg-black md:bg-transparent p-4 md:p-0 shadow md:shadow-none ${isMenuOpen ? "block" : "hidden md:flex"
                                }`}
                            style={isMenuOpen ? { width: "100%" } : undefined}
                        >
                            {user ? ( // Conditionally render based on user state
                                <>
                                    <li className="mb-2 md:mb-0 md:mr-4">
                                        <a href="/dashboard" className="block py-2 md:py-0 hover:text-blue-300">
                                            Dashboard
                                        </a>
                                    </li>
                                    <li className="mb-2 md:mb-0 md:mr-4">
                                        <a href="/notes" className="block py-2 md:py-0 hover:text-blue-300">
                                            Notes
                                        </a>
                                    </li>
                                    <li className="mb-2 md:mb-0 md:mr-4">
                                        <a href="/settings" className="block py-2 md:py-0 hover:text-blue-300">
                                            Settings
                                        </a>
                                    </li>
                                    <li className="mb-2 md:mb-0">
                                        <button
                                            onClick={handleLogout}
                                            className="block py-2 md:py-0 px-6 bg-white hover:bg-gray-200 text-black rounded-full transition duration-300"
                                        >
                                            Sign Out
                                        </button>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="mb-2 md:mb-0 md:mr-4">
                                        <a href="/about" className="block py-2 md:py-0 hover:text-blue-300">
                                            About
                                        </a>
                                    </li>
                                    <li className="mb-2 md:mb-0 md:mr-4">
                                        <a href="/pricing" className="block py-2 md:py-0 hover:text-blue-300">
                                            Pricing
                                        </a>
                                    </li>
                                    <li className="mb-2 md:mb-0 md:mr-4">
                                        <a href="/contact" className="block py-2 md:py-0 hover:text-blue-300">
                                            Contact
                                        </a>
                                    </li>
                                    <li className="mb-2 md:mb-0">
                                        <a
                                            href="/signup"
                                            className="block py-4 md:py-0 px-8 bg-white hover:bg-gray-200 text-black rounded-full transition duration-300"
                                        >
                                            Get Started
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                    )}
                </div>
            </nav>
            {/* <AuthStateDisplay /> */}
        </>
    );
}
