export default function Terms() {
return (
<div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
	<div className="container mx-auto p-8 max-w-screen-sm my-8 bg-white rounded-lg">
		<h1 className="text-2xl font-bold mb-4">Refund Policy</h1>
		<p className="text-sm mb-4">
			Please note that all payments are final, and we do not offer refunds under any
			circumstances. Our products/services are sold as one-time purchases, and once the payment is completed, it
			cannot be reversed or refunded.
		</p>
		<p className="text-sm mb-4">
			If you have any questions or need assistance, feel free to contact our support team.
		</p>
	</div>
</div>
);
}