import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Add this import
import {
    MicrophoneIcon,
    ArrowUpTrayIcon,
    DocumentTextIcon,
    SparklesIcon,
    ListBulletIcon,
    LanguageIcon,
    CloudIcon,
    ShieldCheckIcon,
    ShareIcon,
} from "@heroicons/react/24/outline";

// Import the background image
import bgImage from "../assets/bg.jpeg";
import demo from "../assets/demo.gif";

export default function Home() {
    const { refreshToken } = useAuth(); // Add this line to get the refreshToken function
    const features = [
        {
            name: "Record Your Voice Anytime",
            description:
                "Capture your thoughts on-the-go with our intuitive voice recording feature.",
            icon: MicrophoneIcon,
        },
        {
            name: "Seamless File Uploads",
            description: "Easily upload pre-recorded audio files from your device.",
            icon: ArrowUpTrayIcon,
        },
        {
            name: "Real-Time Transcription",
            description: "Watch your spoken words transform into text instantly. Low latency.",
            icon: DocumentTextIcon,
        },
        {
            name: "AI-Powered Smart Summary",
            description: "Get concise summaries of your recordings with our AI model.",
            icon: SparklesIcon,
        },
        {
            name: "Key Topics at a Glance",
            description:
                "Automatically generated bullet points & highlight the main topics discussed.",
            icon: ListBulletIcon,
        },
        {
            name: "Multilingual Support",
            description: "Communicate effortlessly. 38 languages supported.",
            icon: LanguageIcon,
        },
        {
            name: "Unlimited Storage",
            description: "Never worry about running out of space for your voice notes.",
            icon: CloudIcon,
        },
        {
            name: "Privacy & Security First",
            description: "Your privacy is our priority with secure, accessible recordings.",
            icon: ShieldCheckIcon,
        },
        {
            name: "Share with Others",
            description: "Enjoy the freedom of making your notes public to collaborate.",
            icon: ShareIcon,
        },
    ];

    return (
        <div className="min-h-screen flex flex-col bg-white">
            {/* Hero Section */}
            <div className="relative flex items-center justify-center">
                <img
                    src={bgImage}
                    alt="Hero background"
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black opacity-60"></div>
                <div className="relative z-10 text-center text-white mt-40 mb-40 px-10 sm:px-0">
                    <h1 className="text-4xl sm:text-6xl md:text-6xl font-bold mb-4">Capture Your Thoughts</h1>
                    <p className="text-lg sm:text-xl mb-8">
                        Voice Notes with Real-Time Transcription and Smart Summaries
                    </p>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                        <Link
                            to="/signup"
                            className="bg-blue-800 text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105"
                        >
                            Create Account
                        </Link>
                        <Link
                            to="/share/em15RIrmC7aFitnVI7uK76XR1b32_1725946300726"
                            className="bg-white text-black font-bold py-3 px-6 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105"
                        >
                            View Public Transcript
                        </Link>
                    </div>
                </div>
            </div>
            
            {/* Features Section */}
            <div className="w-full bg-gradient-to-t from-gray-200 to-white py-16 text-gray-900">
                <div className="w-4/5 mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="mb-12 text-center">
                        <h2 className="text-2xl font-bold mb-2 text-blue-900">Enhance your productivity</h2>
                        <p>
                            Explore the amazing features we offer.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
                        {features.map((feature) => (
                            <FeatureCard
                                key={feature.name}
                                Icon={feature.icon}
                                title={feature.name}
                                description={feature.description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function FeatureCard({ Icon, title, description }) {
    return (
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
            <Icon className="w-6 h-6 sm:w-8 sm:h-8 mb-8 sm:mb-6 text-black" />
            <h2 className="text-lg sm:text-xl font-semibold mb-2">{title}</h2>
            <p className="text-sm sm:text-base text-gray-600">{description}</p>
        </div>
    );
}