import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Placeholder from '../components/Placeholder';

export default function SharedNote() {
  const [note, setNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id: shareId } = useParams();
  console.log('shareId in share page:', shareId);

  useEffect(() => {
    const fetchSharedNote = async () => {
      if (!shareId) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://voicenotes-server.onrender.com/api/public-transcript/${shareId}`);
        
        if (response.status === 200) {
          setNote(response.data);
        } else {
          console.log('No shared note found');
        }
      } catch (error) {
        console.error('Error fetching shared note:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSharedNote();
  }, [shareId]);

  if (!shareId) {
    return <div>Welcome to the share page. Please use a valid share ID to view a shared note.</div>;
  }

  if (loading) {
    return <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <Placeholder />
    </div>;
  }

  if (!note) {
    return <div>Shared note not found</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="max-w-3xl w-full bg-white rounded-lg shadow-md p-6 mb-8 mt-8">
        <h1 className="text-2xl font-bold mb-4 text-center">{note.title}</h1>
        <audio src={note.audioUrl} controls className="w-full mb-4" />
        {note.summary && (
          <>
            <h2 className="text-xl font-semibold mb-2">Summary:</h2>
            <p className="mb-4">{note.summary}</p>
          </>
        )}
        {note.details && note.details.length > 0 && (
          <>
            <h2 className="text-xl font-semibold mb-2">Details:</h2>
            <ul className="list-disc list-inside">
              {note.details.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </>
        )}
         <h2 className="text-xl mt-4 font-semibold mb-2">Transcript:</h2>
         <p className="mb-4">{note.transcript}</p>
      </div>
    </div>
  );
}