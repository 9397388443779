export default function Terms() {
	return (
		<div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
			<div className="container mx-auto p-8 max-w-screen-sm my-8 bg-white rounded-lg">
				<h1 className="text-2xl font-bold mb-4">Terms Of Service</h1>
				<h3>1. Introduction</h3>
				<p className="text-sm mb-4">
					By accessing the website at VokalNotes website, you are agreeing to be bound by
					these terms of service, all applicable laws and regulations, and agree that you are
					responsible for compliance with any applicable local laws. If you do not agree with
					any of these terms, you are prohibited from using or accessing this site. The
					materials contained in this website are protected by applicable copyright and
					trademark law.
				</p>
				<h3>2. Use License</h3>
				<p className="text-sm mb-4">
					Permission is granted to temporarily download one copy of the materials (information
					or software) on VokalNotes website for personal, non-commercial transitory viewing
					only. This is the grant of a license, not a transfer of title, and under this
					license you may not:
				</p>
				<p className="text-sm mb-4">
					<li>modify or copy the materials;</li>
					<li>
						use the materials for any commercial purpose, or for any public display
						(commercial or non-commercial);
					</li>
					<li>
						use the materials for any commercial purpose, or for any public display
						(commercial or non-commercial);
					</li>
					<li>remove any copyright or other proprietary notations from the materials</li>
					<li>
						transfer the materials to another person or mirror the materials on any other
						server.
					</li>
				</p>
				<p className="text-sm mb-4">
					This license shall automatically terminate if you violate any of these restrictions
					and may be terminated by VokalNotes at any time. Upon terminating your viewing of
					these materials or upon the termination of this license, you must destroy any
					downloaded materials in your possession whether in electronic or printed format.
				</p>
				<h3>3. Disclaimer</h3>
				<p className="text-sm mb-4">
					The materials on VokalNotes website are provided on an as is basis. VokalNotes makes
					no warranties, expressed or implied, and hereby disclaims and negates all other
					warranties including, without limitation, implied warranties or conditions of
					merchantability, fitness for a particular purpose, or non-infringement of
					intellectual property or other violation of rights.
				</p>
				<h3>4. Limitations</h3>
				<p className="text-sm mb-4">
					In no event shall VokalNotes or its suppliers be liable for any damages (including,
					without limitation, damages for loss of data or profit, or due to business
					interruption) arising out of the use or inability to use the materials on VokalNotes 
					website, even if VokalNotes or a VokalNotes authorized representative has been notified
					orally or in writing of the possibility of such damage. Because some jurisdictions
					do not allow limitations on implied warranties, or limitations of liability for
					consequential or incidental damages, these limitations may not apply to you.
				</p>
				<h3>5. Accuracy of materials</h3>
				<p className="text-sm mb-4">
					The materials appearing on VokalNotes  website could include technical,
					typographical, or photographic errors. VokalNotes does not warrant that any of the
					materials on its website are accurate, complete or current. VokalNotes may make
					changes to the materials contained on its website at any time without notice.
					However VokalNotes does not make any commitment to update the materials.
				</p>
				<h3>6. Links</h3>
				<p className="text-sm mb-4">
					VokalNotes has not reviewed all of the sites linked to its website and is not
					responsible for the contents of any such linked site. The inclusion of any link does
					not imply endorsement by VokalNotes of the site. Use of any such linked website is at
					the user own risk.
				</p>
				<h3>7. Modifications</h3>
				<p className="text-sm mb-4">
					VokalNotes may revise these terms of service for its website at any time without
					notice. By using this website you are agreeing to be bound by the then current
					version of these terms of service.
				</p>
				<h3>8. Governing Law</h3>
				<p className="text-sm mb-4">
					These terms and conditions are governed by and construed in accordance with the laws
					of Rabat and you irrevocably submit to the exclusive jurisdiction of the courts in
					that State or location.
				</p>
			</div>
		</div>
	);
}
