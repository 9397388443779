import { useAuth } from "../context/AuthContext"; // Updated import
import { useState } from 'react'; // Add this import

export default function Home() {
	const { user, loading, accessToken } = useAuth(); // Add accessToken to the destructured values
	const [message, setMessage] = useState(''); // Add state for message

	const creditOptions = [
		{ amount: 200, price: 5, id: '895450' },
		{ amount: 600, price: 15, id: '895561' },
		{ amount: 1200, price: 30, id: '895559' },
	];

	return (
		<div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 pb-8 pt-8">
			<h1 className="text-3xl font-bold mt-4 mb-2 text-blue-900">Pay As You Go</h1>
			<h3 className="text-2xl font-semi-bold mb-8">Keep the transcripts coming.</h3>
			<p className="mb-8 text-center">
				Purchased credit never expires.
				<br />
				Cost per minute: $0.025
			</p>
			<div className="flex flex-wrap justify-center gap-8 mb-6">
				{creditOptions.map(({ amount, price, id }) => (
					<div key={amount} className="bg-white rounded-lg shadow-md p-6 w-64">
						<h3 className="text-xl font-semibold mb-4">{amount} Credits</h3>
						<p className="text-3xl font-bold mb-6">${price.toFixed(2)}</p>
						<button
							onClick={() => {
								console.log('user :', user);
								if (!user) {
									console.log('User not logged in');
									setMessage('Please log in first to make a purchase.'); // Set message for UI
									return; // Exit the function if not logged in
								} else {
									window.Paddle.Checkout.open({
										vendor: 49773,
										email: user.email,
										product: id,
										passthrough: [{ amount: amount, email: user.email, uid: user.uid }],
										method: 'overlay',
										eventCallback: function (data) {
											console.log('Event callback triggered:', data.event);
											if (data.event === "Checkout.Complete") {
												console.log('Checkout completed:', data.eventData);
											}
											else if (data.event === "Checkout.Close") {
												console.log('Checkout closed:', data.eventData);
											}
										}
									});
								}
							}}
							className="w-full bg-gradient-to-l from-blue-900 to-black hover:bg-blue-700 
							text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
						>
							Purchase
						</button>

					</div>
				))}
			</div>
			{message && <p className="text-white p-4 bg-black mb-8 rounded">{message}</p>}
			<p className="text-center">Each credit equals 1 minute of transcription.</p>
		</div>
	);
}
