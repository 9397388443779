export default function Thanks() {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="container mx-auto p-8 max-w-screen-sm my-8 bg-white rounded-lg">
                <h1 className="text-3xl font-bold mb-4 text-center">Thank You</h1>
                <p className="text-sm mb-4">
                    Your transaction was successful, and your package is now active. If you have any questions or need support, feel free to reach out to us anytime.
                </p>
                <p className="text-sm mb-4">
                    Enjoy your experience, and thanks for choosing us!
                </p>
            </div>
        </div>
    );
}