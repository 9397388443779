import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-900 to-black text-white py-8">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <h3 className="text-lg font-semibold mb-4">About Us</h3>
          <p className="text-sm">Capture Your Thoughts: Voice Notes with Real-Time Transcription and Smart Summaries!</p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
          <ul className="text-sm">
            <li><a href="/terms" className="hover:text-blue-300">Terms Of Services</a></li>
            <li><a href="/privacy" className="hover:text-blue-300">Privacy Policy</a></li>
            <li><a href="/refund" className="hover:text-blue-300">Refund Policy</a></li>
            <li><a href="/contact" className="hover:text-blue-300">Contact</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
          <ul className="text-sm">
            <li><a href="#" className="hover:text-blue-300">Facebook</a></li>
            <li><a href="#" className="hover:text-blue-300">Twitter</a></li>
            <li><a href="#" className="hover:text-blue-300">Instagram</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Newsletter</h3>
          <p className="text-sm mb-2">Subscribe to our newsletter for updates</p>
          <input type="email" placeholder="Enter your email" className="w-full p-2 text-gray-800 rounded" />
        </div>
      </div>
      <div className="mt-8 text-center text-sm">
        <p>&copy; 2024 VokalNotes. All rights reserved.</p>
      </div>
    </div>
  </footer>
  );
};

export default Footer;