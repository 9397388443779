import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Updated import
import axios from 'axios'; // Make sure to import axios

export default function Settings() {
	const { user, loading, accessToken } = useAuth(); // Add accessToken to the destructured values
	const [language, setLanguage] = useState("en"); // Default to English
	const [storageLimit, setStorageLimit] = useState(1000); // in MB
	const [isSaving, setIsSaving] = useState(false);
	const [saveSuccess, setSaveSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	const handleLanguageChange = (e) => setLanguage(e.target.value);
	const handleStorageLimitChange = (e) => setStorageLimit(parseInt(e.target.value));

	// Format the createdAt timestamp
	const formatCreatedAt = (timestamp) => {
		if (!timestamp) return 'N/A';
		return new Date(timestamp).toLocaleString();
	};

	useEffect(() => {
		if (!user && !loading) {
			navigate("/login");
		} else if (user && accessToken) {
			fetchUserSettings();
		}
	}, [user, loading, navigate, accessToken]);

	const fetchUserSettings = async () => {
		try {
			const response = await axios.get('https://voicenotes-server.onrender.com/api/user/settings', {
				headers: { 'Authorization': `Bearer ${accessToken}` }
			});

			if (response.status === 200) {
				const settings = response.data;
				setLanguage(settings.language?.value || "en");
				setStorageLimit(settings.storageLimit || 1000);
			} else {
				console.error("Unexpected response status:", response.status);
			}
		} catch (error) {
			console.error("Error fetching user settings:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const saveSettings = async () => {
		if (!accessToken) {
			console.error("No access token available");
			return;
		}

		setIsSaving(true);
		setSaveSuccess(false);

		try {
			// Update language on the server
			const languageOption = document.querySelector(`option[value="${language}"]`);
			const languageLabel = languageOption ? languageOption.textContent : '';

			const response = await axios.post('https://voicenotes-server.onrender.com/api/user/language',
				{
					language: {
						value: language,
						label: languageLabel
					},
					storageLimit
				},
				{
					headers: { 'Authorization': `Bearer ${accessToken}` }
				}
			);

			if (response.status === 200) {
				console.log("Settings updated successfully");
				setSaveSuccess(true);
				// Hide success message after 3 seconds
				setTimeout(() => setSaveSuccess(false), 3000);
			} else {
				console.error("Unexpected response status:", response.status);
			}
		} catch (error) {
			console.error("Error updating settings:", error);
		} finally {
			setIsSaving(false);
		}
	};

	const requestUserData = () => {
		// Implement logic to request user data
		console.log("Requesting user data for:", user?.email);
		// Here you would typically call an API endpoint to initiate the data request process
	};

	if (loading || isLoading) {
		return (
			<div className="flex justify-center items-center h-screen bg-gray-100">
				<div role="status text-center">
					<svg
						aria-hidden="true"
						className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						{/* ... (SVG path remains the same) */}
					</svg>
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	if (!user) {
		return null; // or a message that the user needs to log in
	}

	return (
		<div className="min-h-screen bg-gray-100 p-8 relative">
			{saveSuccess && (
				<div className="fixed top-0 left-0 right-0 bg-green-500 text-white p-4 text-center transition-all duration-500 ease-in-out transform translate-y-0">
					Settings updated successfully!
				</div>
			)}
			<div className="flex justify-center">
				<div className="max-w-2xl w-full">
					<h1 className="text-3xl font-bold mb-8 text-blue-900">Settings</h1>
					<div className="bg-white p-6 rounded-lg space-y-6">
						<div className="mb-4">
							<h2 className="text-xl font-semibold mb-8">User Information</h2>
							<div className="space-y-2">
								<label className="block text-sm font-medium text-gray-700">Email</label>
								<input
									type="email"
									value={user.email}
									disabled
									className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
								/>
							</div>
							<div className="space-y-2 mt-2">
								<label className="block text-sm font-medium text-gray-700">User ID</label>
								<input
									type="text"
									value={user.uid}
									disabled
									className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
								/>
							</div>
							<div className="space-y-2 mt-2">
								<label className="block text-sm font-medium text-gray-700">Created At</label>
								<input
									type="text"
									value={formatCreatedAt(user.metadata?.creationTime)}
									disabled
									className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
								/>
							</div>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700 mb-2">Language</label>
							<select
								value={language}
								onChange={handleLanguageChange}
								className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
							>
								<option value="bg">Bulgarian</option>
								<option value="ca">Catalan</option>
								<option value="zh">Chinese (Mandarin, Simplified)</option>
								<option value="zh-TW">Chinese (Mandarin, Traditional)</option>
								<option value="cs">Czech</option>
								<option value="da">Danish</option>
								<option value="nl">Dutch</option>
								<option value="en">English</option>
								<option value="et">Estonian</option>
								<option value="fi">Finnish</option>
								<option value="nl-BE">Flemish</option>
								<option value="fr">French</option>
								<option value="de">German</option>
								<option value="de-CH">German (Switzerland)</option>
								<option value="el">Greek</option>
								<option value="hi">Hindi</option>
								<option value="hu">Hungarian</option>
								<option value="id">Indonesian</option>
								<option value="it">Italian</option>
								<option value="ja">Japanese</option>
								<option value="ko">Korean</option>
								<option value="lv">Latvian</option>
								<option value="lt">Lithuanian</option>
								<option value="ms">Malay</option>
								<option value="multi">Multilingual (Spanish + English)</option>
								<option value="no">Norwegian</option>
								<option value="pl">Polish</option>
								<option value="pt">Portuguese</option>
								<option value="ro">Romanian</option>
								<option value="ru">Russian</option>
								<option value="sk">Slovak</option>
								<option value="es">Spanish</option>
								<option value="sv">Swedish</option>
								<option value="th">Thai</option>
								<option value="tr">Turkish</option>
								<option value="uk">Ukrainian</option>
								<option value="vi">Vietnamese</option>
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700 mb-2">
								Storage Limit (MB)
							</label>
							<input
								type="number"
								value={storageLimit}
								onChange={handleStorageLimitChange}
								className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
							/>
						</div>
						<button
							onClick={saveSettings}
							disabled={isSaving}
							className={`w-full ${
								isSaving ? 'bg-gray-500' : 'bg-gradient-to-l from-blue-900 to-black'
							} text-white py-2 px-4 rounded-md transition duration-300`}
						>
							{isSaving ? 'Saving...' : 'Save Settings'}
						</button>

						<button
							onClick={requestUserData}
							className="w-full bg-black text-white py-2 px-4 rounded-md transition duration-300"
						>
							Request My Data
						</button>
						<p className="text-sm text-center text-red-600">Delete My Account</p>

					</div>
				</div>
			</div>
		</div>
	);
}
