import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCdc0Yho3KA2uA18UIqYBFGa5UaaNnLYvM",
  authDomain: "voicenotes-ai.firebaseapp.com",
  databaseURL: "https://voicenotes-ai-default-rtdb.firebaseio.com",
  projectId: "voicenotes-ai",
  storageBucket: "voicenotes-ai.appspot.com",
  messagingSenderId: "791731627594",
  appId: "1:791731627594:web:633a19551c72a228c8e8b3"
};

// Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];


export default firebase_app;
export const auth = getAuth(firebase_app);